import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import React, { useMemo } from 'react';
import Image from 'next/future/image';
import Link from 'next/link';
import { buttonVariants } from '../../UI/Button';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { getLangCountry } from '@/helpers/languages';

export default function SectionBaselayers(props: { lang: string }) {
  const { t } = useTranslation();
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);
  let linkRunningMan1 = `/collections/man/apparel-tops?relatedSport=188`;
  let linkRunningWoman1 = `/collections/woman/apparel-tops?relatedSport=188`;

  let linkRunningMan2 = `/collections/man/apparel-pants?relatedSport=188`;
  let linkRunningWoman2 = `/collections/woman/apparel-pants?relatedSport=188`;

  let linkRunningMan3 = `/collections/man/footwear?relatedSport=188`;
  let linkRunningWoman3 = `/collections/woman/footwear?relatedSport=188`;

  let linkRunningMan4 = `/collections/man/socks?relatedSport=188`;
  let linkRunningWoman4 = `/collections/woman/socks?relatedSport=188`;

  const section = useMemo(
    () => ({
      title: t('P_HOME.SECTION-RUNNING.TITLE'),
      cards: [
        {
          title: t('P_HOME.SECTION-RUNNING.CARDS.SHIRTS'),
          men_href: linkRunningMan1,
          woman_href: linkRunningWoman1,
          src: '/v2/home/sports/mar25/1_tshirt.jpg'
        },
        {
          title: t('P_HOME.SECTION-RUNNING.CARDS.PANTS'),
          men_href: linkRunningMan2,
          woman_href: linkRunningWoman2,
          src: '/v2/home/sports/mar25/2_pants.jpg'
        },
        {
          title: t('P_HOME.SECTION-RUNNING.CARDS.SHOES'),
          men_href: linkRunningMan3,
          woman_href: linkRunningWoman3,
          src: '/v2/home/sports/mar25/3_shoes.jpg'
        },
        {
          title: t('P_HOME.SECTION-RUNNING.CARDS.SOCKS'),
          men_href: linkRunningMan4,
          woman_href: linkRunningWoman4,
          src: '/v2/home/sports/mar25/4_socks.jpg'
        }
      ]
    }),
    []
  );

  return (
    <section className="section full section-y-md bg-realblack space-y-md">
      <div className="mb-12 section-wrapper">
        <div className="mx-auto text-center h3 text-white">{section.title}</div>
      </div>

      <Swiper
        wrapperTag="ul"
        slidesPerView={1.2}
        spaceBetween={16}
        navigation={true}
        modules={[Scrollbar, Navigation]}
        scrollbar={{ hide: false }}
        className="px-6 md:px-10 xl:px-16 swiperScrollbar"
        breakpoints={{
          768: {
            slidesPerView: 2.2
          },
          1280: {
            slidesPerView: 3.2
          },
          1536: {
            slidesPerView: 4.2
          }
        }}
      >
        {section.cards.map((category, idx) => (
          <SwiperSlide key={idx} tag="li" className="relative">
            <Image
              src={category.src}
              alt=""
              width={0}
              height={0}
              sizes="100vw"
              className="object-cover w-full h-auto rounded-uyn"
            />

            <span className="absolute inset-0 bg-gradient-to-b from-60% from-transparent to-black/80 rounded-uyn" />

            <div className="absolute inset-0 flex flex-col items-center justify-end py-8 space-y-6 text-white group md:py-12">
              <div className="h5">{category.title}</div>

              <div className="flex flex-wrap justify-center xl:hidden xl:group-hover:flex">
                {category.men_href.trim() != '' && (
                  <Link href={category.men_href} aria-label={t('MAN')}>
                    <a
                      className={clsx(
                        buttonVariants({ variant: 'light' }),
                        'mx-1'
                      )}
                    >
                      {t('MAN')}
                    </a>
                  </Link>
                )}

                {category.woman_href.trim() != '' && (
                  <Link href={category.woman_href} aria-label={t('WOMAN')}>
                    <a
                      className={clsx(
                        buttonVariants({ variant: 'light' }),
                        'mx-1'
                      )}
                    >
                      {t('WOMAN')}
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
