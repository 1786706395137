import React from 'react';
import { useRouter } from 'next/router';
import { filter, flatten, isEmpty, map } from 'lodash';
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';

import {
  DEFAULT_LANG,
  getLangCountry,
  getRealLangCountry
} from '@/helpers/languages';
import { fetchNews } from '@/fetchers/news';
import { News as TNews } from '@/types/News';
import {
  HomeResponseData,
  fetchHome,
  fetchProductsSlider
} from '@/fetchers/home';

import MyHead from '@/components/MyHead';

import Hero from '@/components/v2/Pages/Home/Hero';
import Section1 from '@/components/v2/Pages/Home/Section1';
import Section2 from '@/components/v2/Pages/Home/Section2';
import Section3 from '@/components/v2/Pages/Home/Section3';
import SectionBaselayers from '@/components/v2/Pages/Home/SectionBaselayers';
import SectionRunning from '@/components/v2/Pages/Home/SectionRunning';
import SectionFallwinter from '@/components/v2/Pages/Home/SectionFallwinter';
import SectionNewarrivals from '@/components/v2/Pages/Home/SectionNewarrivals';
import Shoes from '@/components/v2/Pages/Home/Shoes';
import Awards from '@/components/v2/Pages/Home/Awards';
import News from '@/components/v2/Pages/Home/News';
import About from '@/components/v2/Pages/Home/About';
import BigBoxes from '@/components/v2/Pages/Home/BigBoxes';

import { useAlternateUrls } from '@/helpers/seo';
// import ProductsSection from '@/components/v2/ProductsSection';
import { useQuery } from '@tanstack/react-query';
import Kymra from '@/components/v2/Pages/Home/Kymra';

export default function HomePage(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  const { data: products, isLoading } = useQuery({
    queryKey: ['home-products-slider'],
    queryFn: async () => {
      return fetchProductsSlider({ lang, country });
    }
  });

  const {
    layouts: {
      home_main_slider = [],
      home_banner_one = [],
      home_banner_two = []
    }
  } = props.home || {};

  const slidesLang = flatten(
    map(
      filter(home_main_slider, slide => slide.lang === lang),
      ({ sliders }) => sliders
    )
  );

  const slidesEN = flatten(
    map(
      filter(home_main_slider, slide => slide.lang === DEFAULT_LANG),
      ({ sliders }) => sliders
    )
  );

  const cards = filter([...home_banner_one, ...home_banner_two], banner => {
    return (
      Array.isArray(banner.storeview) &&
      banner.storeview.includes(country) &&
      banner.lang === lang
    );
  });

  const slides = !isEmpty(slidesLang) ? slidesLang : slidesEN;

  const alternateUrls = useAlternateUrls('');

  return (
    <>
      <MyHead alternates={alternateUrls} />
      <Hero data={slides} />

      {/* SEZIONE Prodotti in evidenza */}
      {/* <ProductsSection isLoading={isLoading} products={products} /> */}

      {/* SEZIONE New Arrivals */}
      <SectionNewarrivals />

      {/* SEZIONE Winter Edit */}
      {/* <SectionFallwinter /> */}

      {/* SEZIONE NERA Running */}
      <SectionRunning lang={lang} />

      {/* SEZIONE NERA Base Layers */}
      {/* <SectionBaselayers lang={lang} /> */}

      {/* SEZIONE Attività */}
      <Section3 />

      {/* SEZIONE 2 Box Grandi */}
      <BigBoxes />

      {/* SEZIONE Il futuro è Biotech */}
      {/* <Section1 /> */}

      {/* SEZIONE Trova intimo perfetto */}
      {/* <Section2 />  */}

      {/* SEZIONE Categorie scarpe */}
      {/* <Shoes /> */}

      {/* SEZIONE Premi e riconoscimenti */}
      {/* <Awards /> */}

      {/* SEZIONE News */}
      <News data={props.news} />

      {/* SEZIONE Box Azienda e produzione */}
      {/* <About /> */}
    </>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking'
  };
};

export const getStaticProps: GetStaticProps<{
  home: HomeResponseData;
  news: TNews[];
}> = async ctx => {
  const params = getRealLangCountry(ctx.params);

  const [home, news] = await Promise.all([
    fetchHome(params),
    fetchNews(params)
  ]);

  return {
    props: {
      home,
      news
    },
    revalidate: 86_400
  };
};
